<template>
  <div class="tw-px-6">
    <v-row>
      <v-col cols="12">
        <p
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-text-dark-green"
        >
          Finalize your stay
        </p>
        <p class="tw-text-left tw-text-base tw-text-light-grey">
          Know where you’re staying? Add details to share with your crew.
        </p>
        <p class="tw-text-left tw-text-md tw-text-charcoal">
          * Indicates Required
        </p>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="finalizeStay">
            <validation-provider
              v-slot="{ errors }"
              name="linkUrl"
              rules="max:500"
            >
              <j-text-field
                color="secondary"
                v-model="linkUrl"
                type="text"
                :error-messages="errors"
                label="Link"
                class="tw-mt-6"
                customClasses="tw-text-left"
                :loading="metadataPending"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="accommodation.location"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.location"
                type="text"
                :error-messages="errors"
                label="Location*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <div class="tw-mt-6 tw-relative">
              <validation-provider
                v-slot="{ errors }"
                name="accommodation.price"
                rules="required"
              >
                <j-text-field
                  color="secondary"
                  v-model="accommodation.price"
                  type="text"
                  :error-messages="errors"
                  label="Cost*"
                  customClasses="tw-text-left"
                />
              </validation-provider>
              <div class="tw-absolute tw--top-1 tw-right-2">
                <span
                  :class="[
                    'tw-text-xs',
                    'tw-mr-4',
                    'tw-cursor-pointer',
                    'tw-font-semibold',
                    'tw-border-black',
                    {
                      'tw-border-b-2':
                        accommodation.priceType === price_types.PER_NIGHT
                    }
                  ]"
                  @click="accommodation.priceType = price_types.PER_NIGHT"
                  >per night</span
                >
                <span
                  :class="[
                    'tw-text-xs',
                    'tw-cursor-pointer',
                    'tw-font-semibold',
                    'tw-border-black',
                    {
                      'tw-border-b-2':
                        accommodation.priceType === price_types.TOTAL
                    }
                  ]"
                  @click="accommodation.priceType = price_types.TOTAL"
                  >total</span
                >
              </div>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="costPerPerson"
              rules="required"
            >
              <j-text-field
                color="secondary"
                :value="costPerPerson"
                type="text"
                :error-messages="errors"
                label="Cost per person ($) "
                class="tw-mt-6"
                disabled
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.address"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.address"
                type="text"
                :error-messages="errors"
                label="Address"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.name"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.name"
                type="text"
                :error-messages="errors"
                label="Name of Stay*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <span class="j-text-field-label tw-mt-5"
              >Dates: Check In - Check Out</span
            >
            <v-expansion-panels
              v-model="isOpen"
              class="tw-rounded-md tw-mt-2"
              ref="datePanel"
              v-if="isOwner || isOrganizer"
            >
              <v-expansion-panel ref="datesPanel">
                <v-expansion-panel-header>
                  <div
                    class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                  >
                    <v-icon>mdi-calendar-blank</v-icon>
                    <div
                      class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                    >
                      <span v-if="dates.length > 0">{{
                        getFormattedDateRange(dates[0], dates[1])
                      }}</span>
                      <span v-else>Choose a date</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-date-picker
                    @change="closePanel"
                    class="tw-py-4"
                    no-title
                    color="secondary"
                    v-model="dates"
                    range
                    :min="minDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="secondary"
                      @click="dates = []"
                      class="tw-tracking-normal"
                      >Reset Dates</v-btn
                    >
                  </v-date-picker>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.checkInTime"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.checkInTime"
                type="time"
                :error-messages="errors"
                label="Check In Time"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.checkOutTime"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.checkOutTime"
                type="time"
                :error-messages="errors"
                label="Check Out Time"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <span class="tw-hidden">{{ invalid }}</span>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { PRICE_TYPES } from "@/enums/price-types.js";
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
export default {
  name: "AccommodationFinalize",
  mixins: [FormattedDateRange],
  props: ["finalized"],
  data() {
    return {
      dates: [],
      isOpen: null,
      accommodation: {
        linkUrl: "",
        name: "",
        location: "",
        tripId: this.$route.params.id,
        destinationId: this.trip ? this.trip.destinations[0].id : null,
        type: "HOTEL",
        priceType: PRICE_TYPES.TOTAL,
        price: 0,
        imageUrl: "",
        startDate: null,
        endDate: null,
        checkInTime: "",
        checkOutTime: ""
      },
      canSubmit: true,
      linkUrl: null,
      metadata: null,
      metadataPending: false,
      minDate: DateTime.local().toISODate(),
      minEndDate: DateTime.local().toISODate(),
      price_types: PRICE_TYPES
    };
  },
  watch: {
    dates: function (val) {
      if (val[0]) {
        this.accommodation.startDate = val[0];
      }

      if (val[1]) {
        this.accommodation.endDate = val[1];
      }

      if (val[0] && val[1]) {
        let temp = [...val];
        if (new Date(val[0]) > new Date(val[1])) {
          this.dates[0] = temp[1];
          this.dates[1] = temp[0];
          this.accommodation.startDate = temp[1];
          this.accommodation.endDate = temp[0];
        }
      }
    },
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    },
    accommodation: {
      handler: function (val) {
        if (
          val.startDate &&
          val.endDate &&
          new Date(val.endDate) < new Date(val.startDate)
        ) {
          this.canSubmit = false;
          this.$store.dispatch("meta/showGlobalAlert", {
            type: "error",
            text: "Please enter a valid end date",
            timeout: 2000
          });
        } else {
          this.$store.dispatch("meta/hideGlobalAlert");
          this.canSubmit = true;
        }
        this.$nextTick(() => {
          this.accommodation.price = this.accommodation.price
            .toString()
            .replace(/\D/g, "");
        });
      },
      deep: true
    },
    linkUrl(val) {
      this.fetchMetadata(val);
      this.accommodation.linkUrl = val;
    }
  },
  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOrganizer() {
      if (
        !this.trip ||
        !this.sessionUser.trips ||
        this.sessionUser.trips.length === 0
      )
        return false;
      let currentTrip = this.sessionUser.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    },
    isOwner() {
      return this.trip?.ownerId === this.sessionUser.id;
    },
    enable() {
      return (
        this.accommodation.name &&
        this.accommodation.location &&
        this.accommodation.name.length > 0 &&
        this.accommodation.location.length > 0
      );
    },
    costPerPerson() {
      let acceptedUsers = 0;
      if (this.trip)
        acceptedUsers = this.trip?.users.filter(
          (user) => user.trip_invite.status === "accepted"
        ).length;
      if (this.accommodation && acceptedUsers !== 0) {
        const cost = parseInt(this.accommodation.price) / acceptedUsers;
        return Math.round(cost);
      }
      return 0;
    }
  },
  methods: {
    closePanel() {
      const panelRef = this.$refs["datePanel"];
      if (panelRef) this.isOpen = false;
    },
    async fetchMetadata(url) {
      if (this.checkValidUrl(url)) {
        this.metadataPending = true;
        await this.$store
          .dispatch("metascraper/create", { url })
          .then((res) => {
            if (res) {
              this.metadata = res;
              this.metadataPending = false;
              if (this.metadata.title && this.metadata.title.length < 200)
                this.accommodation.name = this.metadata.title;
              if (this.metadata.url)
                this.accommodation.linkUrl = this.metadata.url;
              if (this.metadata.image)
                this.accommodation.imageUrl = this.metadata.image.url;
            }
          })
          .catch((err) => {
            this.metadataPending = false;
            console.log(err);
          });
      }
    },
    goTo(route) {
      this.$router.push(route);
    },
    removeEmpty(obj) {
      /* eslint-disable */
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => {
          return v != null && v != "";
        })
      );
    },
    checkValidUrl(url) {
      try {
        new URL(url);
      } catch (_) {
        return false;
      }
      return true;
    },
    async finalizeStay() {
      if (!this.canSubmit) return;

      // happens when reloading
      if (!this.trip) {
        await this.$store.dispatch("trip/get", [
          this.$route.params.id,
          {
            query: {
              include: "destination,users,survey,trip_invitation"
            }
          }
        ]);
      }
      this.accommodation.destinationId = this.trip.destinations[0].id;
      if (this.accommodation.uuid) {
        try {
          this.accommodation = this.removeEmpty(this.accommodation);
          let strippedPrice = 0;
          if (this.accommodation.price && this.accommodation.price > 0) {
            strippedPrice =
              parseInt(this.accommodation.price.toString().replace(/\D/g, "")) *
              100;
          }
          await this.$store.dispatch("accommodation/patch", [
            this.accommodation.id,
            {
              ...this.accommodation,
              price: strippedPrice
            }
          ]);
          if (sessionStorage.getItem("itinerary")) {
            this.goTo({ name: "TripItineraryList" });
          } else {
            this.goTo({ name: "AccommodationList" });
          }
        } catch (error) {
          this.$store.dispatch("meta/showGlobalAlert", {
            type: "error",
            text: error.message
              ? error.message
              : "Error updating accommodation",
            timeout: 2000
          });
        }
      } else {
        try {
          this.accommodation = this.removeEmpty(this.accommodation);
          let strippedPrice = 0;
          if (this.accommodation.price && this.accommodation.price > 0) {
            strippedPrice =
              parseInt(this.accommodation.price.toString().replace(/\D/g, "")) *
              100;
          }
          const create = await this.$store.dispatch("accommodation/create", {
            ...this.accommodation,
            price: strippedPrice
          });
          if (sessionStorage.getItem("itinerary")) {
            this.goTo({ name: "TripItineraryList" });
          } else {
            this.goTo({ name: "AccommodationList" });
          }
        } catch (error) {
          this.$store.dispatch("meta/showGlobalAlert", {
            type: "error",
            text: error.message
              ? error.message
              : "Error creating accommodation",
            timeout: 2000
          });
        }
      }
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Places To Stay",
      bgColor: "#D6BCFF",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      text: "Save",
      isDisabled: true,
      method: this.finalizeStay,
      bgColor: "tw-bg-chartreuse"
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);
  },
  mounted() {
    if (this.finalized) {
      this.accommodation = this.finalized;
      if (this.accommodation.startDate)
        this.dates[0] = this.accommodation.startDate;
      if (this.accommodation.endDate)
        this.dates[1] = this.accommodation.endDate;
      this.linkUrl = this.accommodation.linkUrl;
      this.accommodation.price = this.accommodation.price / 100;
    }
  },
  destroyed() {
    sessionStorage.removeItem("itinerary");
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>
